<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <CCard>
      <CCardBody class="px-1 pt-3 pb-0">
        <div class="d-flex flex-wrap">
          <div class="pr-2 mb-sm-0 mb-2">
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div class="flex-grow-1">
            <CPagination
              align="end"
              :dots='false'
              v-show="pages > 1"
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!-- :active-page="1" -->
    <CDataTable
      clickable-rows
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{ external: true, lazy: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No menu group childs found!',
      }"
      @row-clicked="rowClicked"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @update:column-filter-value="columnFilter"
      @pagination-change="paginationChange"
    >

      <template #id_="{ item }">
        <td class="move-item">
          <CIcon name="cil-move" :data-id="item.id" class="align-bottom" />
        </td>
      </template>

      <template #select="{item}">
        <td>
          <CInputCheckbox
            :checked="item._selected"
            @update:checked="() => check(item)"
            custom
          />
        </td>
      </template>

      <template #price_currency="{item}">
        <td>{{ item.Price | toCurrency }}</td>
      </template>

      <template #actions="{ item, index }">
        <td class="py-2">
          <CButton size="sm"
            :to="{ name: 'Edit Menu Group Child', params: { mnuExtGrpId: $route.params.mnuExtGrpId, id: item.id }}"
            color="info"
            ><CIcon name="cil-align-left" /></CButton
          >

          <CButton
            size="sm"
            color="danger"
            class="ml-1"
            @click="deleteItem(item, index)"
            ><CIcon name="cil-trash" /></CButton
          >
        </td>
      </template>
    </CDataTable>

    <CPagination
      v-show="pages > 1"
      :pages="pages"
      :active-page.sync="activePage"
      align="center"
    />

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>


<script>
import Sortable from "sortablejs";

export default {
  name: "BackendTable",
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "id_", label: "", sorter: false, filter: false, _style: "min-width: 1%" },
        { key: "select", label: "", sorter: false, filter: false, _style: "width: 1%" },
        { key: "Index", label: "Index", filter: false, _style: "width: 4%" },
        { key: "Name", label: "Name", filter: false },
        { key: "price_currency", label: "Price", filter: false },
        { key: "menu_linked_ext_gr_childs_count", label: "Linked Menus", filter: false, _style: "width: 15%" },
        { key: "restaurant_order_detail_childs_count", label: "In Order Items", filter: false, _style: "width: 15%" },
        { key: "actions", label: "Actions", filter: false, sorter: false, _style: "min-width: 1%; width: 10%" }
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },
  // BackendTable Alert işlemleri için
  props: {
    successAlertMessage: {
      type: Object,
      required: true
    },
    dismissSecs: {
      type: Number,
      required: true
    },
    dismissSuccessAlert: {
      type: Number,
      required: true
    },
    errorAlertMessage: {
      type: Object,
      required: true
    },
    showErrorAlert: {
      type: Boolean,
      required: true
    }
  },
  // created() {
  //   this.getAllItems();
  // },
  mounted() {
    this.activePage =  this.queryPage;
    window.onscroll = () => {
        this.mcSpinnerMarginTop = document.documentElement.scrollTop;
      }
    this.getAllItems();

    this.$root.$on('childs-added-from-restaurant-category', () => {
      // Refresh on adding childs from restaurant category!
      this.getAllItems()
    });

    //#region Sortablejs işlemleri
    var itemID, newIndex;
    var el = document.getElementsByTagName("tbody");

    var sortable = Sortable.create(el[0], {
      handle: "svg.c-icon",
      chosenClass: "table-selected",
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",

      onSort: function (/**Event*/ evt) {
        var itemEl = evt.item; // dragged HTMLElement
        newIndex = evt.newIndex;
        itemID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-id");
        // console.log(itemID, newIndex);
      },
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: () =>
          this.storeNewIndexes(
            parseInt(itemID),
            newIndex + ((this.activePage - 1) * this.itemsPerPage)
          ),
      },
    });
    //#endregion Sortablejs ---
  },
  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Menu Group Childs', params: { mnuExtGrpId: this.menuGroupId }, query: { page: this.activePage } });

      this.onTableChange();
    },
  },
  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
    menuGroupId () { return this.$route.params.mnuExtGrpId },
  },
  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.MENU_GROUP_CHILDS.GET_ALL.replace("{mnuExtGrpId}", this.menuGroupId)),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        )
          url.searchParams.append(key, params[key]);
      });

      // Sortablejs işleminden sonra sayfa geçişlerinde index değerinde göre sıralanmış ögeler
      // düzensiz sırada geliyordu. Bunu önlemek için loadedItems dizisini sıfırlamak gerekti.
      // Sortablejs kullanılmayacaksa aşağıda satır kaldırılabilir.
      this.loadedItems = []

      this.$axios
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { //Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      this.search = search;
      this.getAllItems();
    },
    columnFilter(filter) {
      //console.log(JSON.stringify(filter))
      // Object.keys(filter).forEach((key) => {
      //   console.log(key, filter[key]);
      // });
    },
    rowClicked (item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A'].includes(e.target.tagName)) {
        this.check(item)
      }
    },
    check (item) {
      this.$set(item, "_selected", !item._selected);
      item._classes = item._selected ? 'table-selected' : ''
    },


    deleteItem(item, index) {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to delete?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios
              .delete(
                this.$backend.MENU_GROUP_CHILDS.DELETE.replace("{id}", item.id)
              )
              .then((response) => {
                this.$delete(this.loadedItems, index);
                this.$emit('update:successAlertMessage', {itemName: item.Name, message: 'menu group child deleted.'});
                this.$emit('update:dismissSuccessAlert', this.dismissSecs);
                this.$emit('update:showErrorAlert', false);
              })
              .catch((error) => {
                this.$emit('update:errorAlertMessage', error.response.data);
                this.$emit('update:showErrorAlert', true);
              })
              .finally(() => {
                window.scrollTo(0,0);
                this.loading = false;
              });
            // ---------------
          }
        },
      });
    },

    storeNewIndexes(itemID, newIndex) {
      // console.log(itemID, newIndex);

      if(isNaN(itemID) || isNaN(newIndex))
        return;

      this.loading = true;

      // Dizi index'i 0'dan başlıyor, ancak bizim 0. elemanın index'i 1'den başlıyor.
      newIndex++;

      // For Front-End
      var item = this.loadedItems.find(item => item.id == itemID);
      var oldIndex = item.Index;
      if (newIndex > oldIndex) {
        this.loadedItems.filter((item) => {
          return item.Index > oldIndex && item.Index <= newIndex;
        }).forEach((value, index) => { value.Index--; });
      }
      else if (newIndex < oldIndex) {
        this.loadedItems.filter((item) => {
          return item.Index < oldIndex && item.Index >= newIndex;
        }).forEach((value, index) => { value.Index++; });
      }
      item.Index = newIndex;

      // For Back-End
      this.$axios
        .get(this.$backend.MENU_GROUP_CHILDS.STORE_NEW_INDEXES.replace("{mnuExtGrpId}", this.menuGroupId), {
          params: { itemID, newIndex: newIndex, mnuExtGrpId: this.menuGroupId },
        })
        // .then(this.getAllItems)
        // .then((response) => {console.log(response);})
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
