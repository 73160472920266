<template>
    <CModal
      class="restaurant-category-modal"
      title="Add New Menu Group Child"
      color="dark"
      :show.sync="active"
      centered
      :closeOnBackdrop="false"
    >
      <template>
        <v-select
            class="v-select-filter mb-4"
            placeholder="Select restaurant.."
            v-model="selectedRestaurant"
            :options="allRestaurants"
            @input="inputFilter"
            :disabled="restaurantId"
        />
        
        <v-select
            class="v-select-filter mb-4"
            placeholder="Select category.."
            v-model="selectedCategory"
            :options="allCategories"
            @input="inputFilterCategory"
        />

        <CForm>
            <SelectCategoryMenuChild
                label="Available Menu Items"
                :items="menus"
                :fields="menusFields"
                :loading="menusLoading"
                :selectedItems="form.mnuExtGrpChilds"
            ></SelectCategoryMenuChild>
        </CForm>
      </template>
  
      <template #footer class="p-1">
        <CButton 
            color="primary" 
            :disabled="!(form.mnuExtGrpChilds.length > 0) || submitted"
            @click="store()"
        >
            Submit
        </CButton>
        <CButton @click="active=false" color="secondary">Close</CButton>
      </template>

      <template>
        <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
      </template>
    </CModal>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate"
  import { required, minLength, integer, numeric } from "vuelidate/lib/validators"
  import SelectCategoryMenuChild from "./SelectCategoryMenuChild";
    import { CButton } from "@coreui/vue-pro";
  
  export default {
    name: "RestaurantCategory",

    components: {
        SelectCategoryMenuChild
    },
  
    data() {
      return {
        form: {
            mnuExtGrpChilds: []
        },
        submitted: false,

        //Restaurants list
        allRestaurants: [],
        selectedRestaurant: null,
        
        //Category list
        allCategories: [],
        selectedCategory: null,

        //Selection Panel
        menus: [],
        menusFields: [
            { key: "select", label: "", sorter: false, filter: false, _style: "width: 1%" },
            { key: "Name", label: "Name", filter: false, _style: "width: 50%" },
            { key: "Price", label: "Price", filter: false, _style: "width: 49%" },
        ],
        menusLoading: false,

        // Element Cover Spinner
        mcSpinnerMarginTop: 0,
        loading: false,
      };
    },
    // BackendTable Alert işlemleri için
    props: {
      successAlertMessage: {
        type: Object,
        required: true
      },
      dismissSecs: {
        type: Number,
        required: true
      },
      dismissSuccessAlert: {
        type: Number,
        required: true
      },
      errorAlertMessage: {
        type: Object,
        required: true
      },
      showErrorAlert: {
        type: Boolean,
        required: true
      }
    },
  
    mixins: [validationMixin],
    validations: {
      form: {
        mnuExtGrpChilds: { 
            required,
            minLength: minLength(1),
            $each: {
                Name: {
                    required
                },
                Price: {
                    required,
                    minLength: minLength(2)
                },
            }
         },
      }
    },

    async mounted() {
      window.onscroll = () => {
        this.mcSpinnerMarginTop = document.documentElement.scrollTop;
      }
        await Promise.all([
            this.getRestaurants(),
        ]);
    },
  
    computed: {
      isValid() { return !this.$v.form.$invalid },
      isDirty() { return this.$v.form.$anyDirty },
      active: {
        get: function () { return this.$store.state.restaurantCategoryShow },
        set: function (newValue) { this.$store.state.restaurantCategoryShow = newValue }
      },
      showFormSummary() { return this.form && !this.isValid && this.isDirty },
      restaurantId() { return this.$route.params.restaurant_id },
      menuGroupId() {
        return this.$route.params.mnuExtGrpId;
      },
    },
  
    watch: {
      active: async function (val) {
        this.reset();
      },
    },
  
    methods: {
        async getRestaurants() {
            await this.$axios
                .get(this.$backend.RESTAURANT.GET_ALL_NAME)
                .then((response) => {
                this.allRestaurants = response.data;

                if(this.restaurantId) {
                    this.selectedRestaurant = this.allRestaurants.find(item => item.value == this.restaurantId);
                }

                this.selectedCategory = null;
                })
                .catch(() => { 
                    this.allRestaurants = [];
                    this.allCategories = [];
                })
        },
        
        async getCategories(id) {
            await this.$axios
                .get(this.$backend.RESTAURANT_LINKED_CATEGORIES.GET_ALL_BY_RESTAURANT_ID_WITH_MENUS.replace("{restaurant_id}", id))
                .then((response) => {
                this.allCategories = response.data;
                this.loading = false;
                })
                .catch(() => { this.allCategories = []; this.loading = false; })
        },

        // Filters
        inputFilter() {
          this.loading = true;
          this.selectedCategory = null;
          this.allCategories = [];
          this.menus = [];
          this.form.mnuExtGrpChilds = [];
          this.getCategories(this.selectedRestaurant?.value);
        },

        inputFilterCategory() {
            this.menusLoading = true;
            this.menus = [];
            this.form.mnuExtGrpChilds = [];
            if(this.selectedCategory) {
                this.allCategories.find(item => {
                    if(item.value == this.selectedCategory?.value) {
                        for (let i = 0; i < item.menus.length; i++) {
                            const element = item.menus[i];
                            const menusElement = {
                                'Name': element.menu_name,
                                'Price': null,
                                _classes: 'grayout',
                            };
                            this.menus.push(menusElement);
                        }
                    }
                });
            }
            this.menusLoading = false;
        },

        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
            return null
            }
            return !(field.$invalid || field.$model === '')
        },
  
        validate () {
            this.$v.$touch()
        },
  
        async store() {
            if (this.isValid) {
                this.submitted = true
                this.loading = true;
                // -(1)- Form Bilgilerini Kaydetme
                this.$axios.post(this.$backend.MENU_GROUP_CHILDS.STORE_MULTIPLE.replace("{mnuExtGrpId}", this.menuGroupId), this.form)
                .then((response)=>{
                    this.$emit('update:successAlertMessage', {itemName: "All childs from restaurant category", message: ' are added to menu group.'});
                    this.$emit('update:dismissSuccessAlert', this.dismissSecs);
                    this.$emit('update:showErrorAlert', false);
                    this.reset();
                    this.active = false;
                    this.$root.$emit('childs-added-from-restaurant-category');
                    this.loading = false;
                    this.submitted = false
                })
                .catch((error)=>{
                    this.$emit('update:errorAlertMessage', error.response.data);
                    this.$emit('update:showErrorAlert', true);
                    this.submitted = false
                    this.loading = false;
                })
            } // -(1)- Form Bilgilerini Kaydetme / end
            else {
                this.validate()
            }
        },
  
        reset() {
            this.form = {
              mnuExtGrpChilds: []
            };
            this.$v.$reset()

            this.menus = [];
            this.allCategories = [];
            this.form.mnuExtGrpChilds = [];

            this.selectedCategory = null;
            this.selectedRestaurant = null;
        },
    },
  };
  </script>
  
  <style>
  .restaurant-category-modal .modal-header {
    padding: 0.75rem !important;
  }
  .restaurant-category-modal .modal-footer {
    padding: 0.25rem !important;
  }
  
  .prices-table table {
    margin-bottom: 0;
  }
  
  .prices-table table th, .prices-table table td {
    padding: 0.75rem;
    vertical-align: middle;
  }
  
  .restaurant-category-modal .form-errors div:nth-child(1):before {
    display: block;
    content: "Plase check!";
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  /* Price type: both */
  .price-type .custom-radio:nth-child(3) {
    display: none;
    pointer-events: none;
    opacity: 0.6;
  }
  
  .restaurant-category-modal .form-control {
    font-weight: bold;
  }
  </style>
  