<template>
    <div class="selection-panel">
      <div>
        <h2>{{label}}</h2>
        <CDataTable
            striped
            hover
            size="sm"
            :items="items"
            :fields="fields"
            :loading="loading"
            :noItemsView="{
                noResults: 'No filtering results are available!',
                noItems: 'No menu found!',
            }"
        >
            <!-- Check Box -->
            <template #select="{item}">
                <td class="text-right">
                    <CInputCheckbox
                        class="ml-1"
                        :checked="item._selected"
                        @update:checked="() => select(item)"
                        custom
                    />
                </td>
            </template>

            <!-- Price -->
            <template #Price="{item}">
                <td>
                    <CInput
                        class="mb-0"
                        :lazy="false"
                        :value.sync="item.Price"
                        placeholder="0.00"
                        type="number"
                        step="0.10"
                        pattern="^\d+(?:\.\d{1,2})?$"
                        onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
                        :disabled="item.eol == 1"
                        @input="(value) => {
                            //item._classes = 'table-selected';
                            //updateMenuItemPrice(item, value);
                            if(!item._selected && value) {
                                select(item);
                            }

                            if(item._selected && !value) {
                                deselect(item);
                            }
                        }"
                    >
                        <template #prepend-content><CIcon name="cil-euro" /></template>
                    </CInput>
                </td>
            </template>
        </CDataTable>

        <CCard class="actions sticky-bottom">
            <CCardBody class="p-2">
                <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
                    <div class="action d-flex w-50 flex-gap-2">
                        <CButton :disabled="(!(items.length > 0) || (items.length == selectedItems.length))" color="info" @click="selectAllItems">Select All</CButton>
                        <CButton :disabled="(!(items.length > 0) || (items.length != selectedItems.length))" color="danger" @click="deselectItems">Deselect All</CButton>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SelectCategoryMenuChild",
    data() {
      return {
      };
    },
    props: {
      label: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      fields: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      },
      selectedItems: {
        type: Array,
        required: true
      }
    },
    methods: {
        select(item) {
            this.$set(item, "_selected", !item._selected);

            if (item._selected) {
                item._classes = item._classes.replace("grayout", " ");
                this.selectedItems.push(item);
            } else {
                item._classes = item._classes.replace(" ", "grayout");
                item.Price = null;
                this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
            }
        },
        deselect(item) {
            this.$set(item, "_selected", false);
            item._classes = item._classes.replace(" ", "grayout");
            item.Price = null;
            this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        },
        selectAllItems() {
            this.deselectItems();

            this.items
                .forEach(item => {
                this.$set(item, "_selected", true);
                item._classes = item._classes.replace("grayout", " ");
                this.selectedItems.push(item);
                });
        },
        deselectItems() {
            this.items
                .forEach(this.deselect);
        },
    }
  };
  </script>
  
  <style scoped>
    .selection-panel {
        align-items: center;
    }
    
    .selection-panel h2 {
        font-size: 1.2rem;
    }
    
    .selection-panel select {
        height: 200px;
        width: 100%;
        border: none;
        box-shadow: 0 1px 1px 0 rgba(var(--elevation-base-color), .14), 0 2px 1px -1px rgba(var(--elevation-base-color), .12), 0 1px 3px 0 rgba(var(--elevation-base-color), .20);
    }
    
    .selection-panel select:focus-visible {
        outline: none;
    }

    .selection-panel select::-webkit-scrollbar-track
    {
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .selection-panel select::-webkit-scrollbar
    {
        width: 4px;
        background-color: #F5F5F5;
    }

    .selection-panel select::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555;
    }
  
    .selection-panel .middle {
        text-align: center;
    }
    
    .selection-panel button {
        width: 80%;
        margin-bottom: 5px;
    }
  </style>