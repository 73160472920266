<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-object-ungroup" />
          <h5 class="d-inline ml-2">
            <h3 class="d-inline"><CBadge color="secondary">{{ this.menuGroupName | uppercase }}</CBadge></h3>
            Menu Group Childs
          </h5>
          <div class="card-header-actions">
            <CButton color="success" class="mx-2" @click="showRestaurantCategory()">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Child From Restaurant Category
            </CButton>
            <CButton color="primary" :to="{ name: 'Add New Menu Group Child' }">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Menu Group
              Child
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable
          :successAlertMessage.sync="successAlertMessage"
          :dismissSecs.sync="dismissSecs"
          :dismissSuccessAlert.sync="dismissSuccessAlert"
          :errorAlertMessage.sync="errorAlertMessage"
          :showErrorAlert.sync="showErrorAlert"
        />
        <RestaurantCategory 
          :successAlertMessage.sync="successAlertMessage"
          :dismissSecs.sync="dismissSecs"
          :dismissSuccessAlert.sync="dismissSuccessAlert"
          :errorAlertMessage.sync="errorAlertMessage"
          :showErrorAlert.sync="showErrorAlert"
        />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable-Sortable";
import RestaurantCategory from "./RestaurantCategory";

export default {
  name: "MenuGroupChilds",
  components: {
    BackendTable,
    RestaurantCategory
  },
  data() {
    return {
      menuGroupName: "",

      
      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,
    };
  },
  mounted() {
    this.getMenuGroupName();
  },
  computed: {
    menuGroupId() {
      return this.$route.params.mnuExtGrpId;
    },
  },
  methods: {
    getMenuGroupName() {
      this.$axios
        .get(this.$backend.MENU_GROUPS.SHOW.replace("{id}", this.menuGroupId))
        .then((response) => {
          this.menuGroupName = response.data.Name;
        })
        .catch((error) => { this.menuGroupName = 'Not Found' })
    },
    showRestaurantCategory() {
      this.$store.state.restaurantCategoryShow = true;
    },
  },
};
</script>
